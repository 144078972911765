import type { NextPage } from 'next';
import Head from 'next/head';
import Image from 'next/image';
import cx from 'classnames';

import { ClientSection } from 'components/ClientSection';
import { Button } from 'components/Button';
import { ClientTitle } from 'components/ClientTitle';
import { ContactForm } from 'components/ContactForm';
import Link from 'next/link';
import { buildLandingUrl, LANDING_IDS, ROUTES } from 'common/constants';
import { StatesPicker } from 'components/StatesPicker';
import { GeneralLayout } from 'components/layouts/GeneralLayout';
import EnvelopeIcon from 'public/assets/icons/envelope.svg';
import PointerIcon from 'public/assets/icons/pointer.svg';
import BackgroundImage from 'public/assets/bg-main.jpg';
import PuzzleIcon from 'public/assets/icons/puzzle.svg';
import MoneyIcon from 'public/assets/icons/money.svg';
import WhyUsImage from 'public/assets/why-us-bg.png';
import ContactImage from 'public/assets/bg-contact.jpg';
import DiskIcon from 'public/assets/icons/disk.svg';
import FileIcon from 'public/assets/icons/file.svg';
import Step1Image from 'public/assets/step-1.png';
import Step2Image from 'public/assets/step-2.png';
import Step3Image from 'public/assets/step-3.png';
import { useAuth } from 'context/AuthContext';
import LocationIcon from 'public/assets/icons/location.svg';
import PhoneIcon from 'public/assets/icons/phone.svg';

const Home: NextPage = () => {
  const { isAuthenticated } = useAuth();

  return (
    <GeneralLayout
      className="bg-main"
      skipContainer
      navbarProps={{ className: 'z-10 absolute top-0 w-full', transparent: true }}
    >
      <Head>
        <title>Home - Valley Insurance</title>
      </Head>

      <div
        style={{ background: `url(${BackgroundImage.src}) center top / cover no-repeat` }}
        className="h-screen pt-32 md:pt-30 lg:pt-30 xl:pt-32 2xl:pt-40"
      >
        <ClientSection
          title="Surplus lines filing experts"
          className="mx-auto text-white px-4 pt-10 md:pt-60 md:pb-80 text-center md:text-left"
        >
          <h2 className="text-4xl md:text-5xl max-w-2xl leading-tight">
            Streamline your surplus line filings and reporting process!
          </h2>
          <p className="mx-6 md:mx-0 py-4 max-w-3xl md:text-lg">
            Save your staff from the headache of filing surplus lines taxes, reports and staying current on
            ever-changing tax rates, forms, deadlines, requirements and laws!
          </p>
          <Link href={buildLandingUrl(LANDING_IDS.SUBMIT_FILING)}><Button className='mx-auto md:mx-0'>Submit a filing</Button></Link>
        </ClientSection>
      </div>

      <ClientSection
        id={LANDING_IDS.SUBMIT_FILING}
        title="Select the state to submit filing"
        className="mx-auto text-white text-center py-20 md:py-40"
        vertical
      >
        <h2 className="text-2xl md:text-4xl text-center">File now by clicking below</h2>
        <p className="max-w-xl md:mx-auto mt-3 mb-8 mx-4 md:text-lg">
          Select the state you wish to file in and you will be directed to
          that state’s tax calculator and applicable forms
        </p>
        <StatesPicker className="max-w-6xl mx-auto" showMap/>
      </ClientSection>

      <div
        id={LANDING_IDS.WHY_US}
        className="py-20"
        style={{
          background: `url(${WhyUsImage.src}) center center / cover no-repeat fixed`
        }}
      >
        <ClientSection title="Why us?" className="mx-auto text-white text-center relative px-4" vertical>
          <h2 className="max-w-xl text-2xl md:text-4xl mx-auto">
            Why Use Valley Insurance Associates, LLC?
          </h2>
          <div className="max-w-5xl mx-auto grid md:grid-cols-3 mt-16 gap-10 md:py-8">
            <div className="flex flex-col justify-center">
              <MoneyIcon className="mb-4 md:mb-10 h-16 md:h-20"/>
              <p>Save money on licensing costs in 36 states by allowing us to handle your surplus lines filings, whether
                using our licenses or yours. Registration is free and there is no obligation to utilize our
                services.</p>
            </div>
            <div className="flex flex-col justify-center">
              <EnvelopeIcon className="mb-4 md:mb-10 h-16 md:h-20 fill-accent"/>
              <p>Email confirmation of every policy and endorsement processed with the state. Endorsements onto existing
                policies are processed at no additional cost.</p>
            </div>
            <div className="flex flex-col justify-center">
              <FileIcon className="mb-4 md:mb-10 h-16 md:h-20"/>
              <p>Zero Business state report filings for your surplus lines licenses – several states require reports to
                be filed even though no business has been transacted.</p>
            </div>
            <div className="flex flex-col justify-center">
              <PuzzleIcon className="mb-4 md:mb-10 h-16 md:h-20"/>
              <p>Mix and match services to best support your office needs, freeing up staff time to allow your office to
                focus on generating additional revenue.</p>
            </div>
            <div className="flex flex-col justify-center">
              <DiskIcon className="mb-4 md:mb-10 h-16 md:h-20"/>
              <p>Reporting surplus lines taxes under your licenses in the states we do not offer courtesy filings in.
                Service includes all necessary monthly, quarterly, and annual state reporting requirements for one low
                fee. </p>
            </div>
            <div className="flex flex-col justify-center">
              <PointerIcon className="mb-4 md:mb-10 h-16 md:h-20"/>
              <p> Collectively, our staff has over 48 years of experience processing surplus lines taxes and reports.
                While delivering excellent customer service, we stay abreast of ever-changing laws and regulations.</p>
            </div>
          </div>
        </ClientSection>
      </div>

      <ClientSection
        title="Submit filing in a few minutes"
        className="mx-auto text-white px-4 py-10 md:py-40 text-center md:text-left"
        id={LANDING_IDS.HOW_IT_WORKS}
      >
        <h2 className="text-2xl md:text-4xl">How it works?</h2>
        <p className="max-w-4xl md:text-lg py-4">
          Streamline your surplus lines filings and reporting process. All your filings are connected to your account,
          allowing you to preview them at any time. You can see the status of your filings, have all the required
          documents in one place and have access to a history of all your submitted and returned filings.
        </p>

        <div className="flex flex-col gap-24 mt-10 md:mt-20">
          {[
            {
              title: 'Select the state',
              description: 'Start by selecting the state for which you want to submit a filing. You can use a map or select the state from a dropdown.',
              image: Step1Image
            },
            {
              title: 'Submit the filing forms',
              description: 'Each state is unique and has different requirements. Our interactive site will guide you through the entire process, providing required forms and prompts to submit necessary documents.',
              image: Step2Image
            },
            {
              title: 'Check filing status',
              description: 'Track all your filings, see the history and have access to all the documents in one place.',
              image: Step3Image
            }
          ].map((item, idx) => (
            <div className={cx('flex flex-col md:flex-row', idx % 2 === 1 && 'md:flex-row-reverse')} key={idx}>
              <div className={cx('flex-1 flex flex-col justify-center', idx % 2 === 1 && 'md:ml-20')}>
                <div className="relative mb-4 md:mb-0 pt-20 md:pt-0">
                  <div className="font-playfair text-[20em] leading-10 opacity-10 absolute md:-top-10">
                    0{idx + 1}
                  </div>
                  <ClientTitle skipMargin>{item.title.toUpperCase()}</ClientTitle>
                  <p className="max-w-md md:text-lg mt-1">{item.description}</p>
                </div>
              </div>
              <div className={cx('flex-1 z-10', idx % 2 === 0 && 'text-center')}>
                <Image src={item.image} role="presentation" className="max-w-full h-full" alt={`Step ${idx + 1}`}/>
              </div>
            </div>
          ))}
        </div>
      </ClientSection>

      <div className="bg-accent md:text-left text-center">
        <ClientSection
          title="Submit Filing in a few minutes"
          className="mx-auto py-12"
          titleClassName="color-main"
          inverted
        >
          <div className="flex flex-col md:flex-row">
            <h2 className="text-2xl md:text-4xl">
              {!isAuthenticated ? 'Create an account now!' : 'Select the state and file now'}
            </h2>

            {!isAuthenticated && (
              <Link href={ROUTES.SIGNUP} className="mt-4 md:mt-0 md:ml-auto">
                <Button variant="dark">Create an account</Button>
              </Link>
            )}

            {isAuthenticated && (
              <StatesPicker className="mt-4 md:mt-0 md:ml-auto"/>
            )}
          </div>
        </ClientSection>
      </div>

      <div
        id={LANDING_IDS.CONTACT}
        className="py-20 px-4"
        style={{ background: `url(${ContactImage.src}) center center / cover no-repeat fixed` }}
      >
        <ClientSection title="Surplus lines filing experts" className="md:py-40 mx-auto text-white text-center md:text-left">
          <h2 className="text-2xl md:text-4xl">Contact us</h2>
          <p className="font-medium text-lg mt-2">Valley Insurance Associates, LLC</p>

          <div className="flex flex-col md:flex-row mt-16 text-left">
            <div className="flex-1 md:mr-32">
              <div className="flex flex-col gap-4">
                <div className="flex gap-4 items-center">
                  <div className="w-8 flex items-center justify-center">
                    <EnvelopeIcon className="h-6 fill-accent"/>
                  </div>
                  PO Box 1973, Sumner, WA 98390
                </div>
                <div className="flex gap-4">
                  <div className="w-8 flex items-center justify-center">
                    <LocationIcon className="h-6 fill-accent"/>
                  </div>
                  307 29th St. NE #107 Puyallup, WA 98372
                </div>
                <a href="tel:2534663296" className="flex gap-4 text-white">
                  <div className="w-8 flex items-center justify-center">
                    <PhoneIcon className="h-6 text-center stroke-accent"/>
                  </div>
                  253-466-3296
                </a>
              </div>

              <div className="grid md:grid-cols-2 gap-4 mt-10">
                {[
                  { name: 'Gina Doyle', title: 'Surplus Lines Broker', mail: 'gina@valleyinsllc.com' },
                  { name: 'Melinda Craig', title: 'Surplus Lines Tax Specialist', mail: 'melinda@valleyinsllc.com' },
                  { name: 'Rachel Toney', title: 'Surplus Lines Tax Specialist', mail: 'rachel@valleyinsllc.com' },
                  { name: 'Alexa Garside', title: 'Surplus Lines Report Specialist', mail: 'alexa@valleyinsllc.com' }
                ].map((item, idx) => (
                  <div className="flex flex-col gap-1" key={idx}>
                    <div className="font-medium text-lg">{item.name}</div>
                    <div>{item.title}</div>
                    <div>
                      <a href={`mailto:${item.mail}`} className="text-accent underline">{item.mail}</a>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex-1 mt-12 md:mt-0">
              <ContactForm/>
            </div>
          </div>
        </ClientSection>
      </div>
    </GeneralLayout>
  );
};

export default Home;
